<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">Edit Announcement</p>
    </div>
    <div
      class="dialog-content"
      style="overflow-y: auto !important;"
      v-bind:style="{height: contentHeightOverride}">
      <div class="container-fluid">
        <b-form class="edit-announcement-form">
          <b-form-group
            label="Title"
            label-for="title"
            label-cols="2"
            horizontal>
            <b-form-input
              id="title"
              type="text"
              aria-describedby="title-input"
              autocomplete="off"
              placeholder="Enter Title"
              v-model.trim="$v.form.title.$model"
              @input="$v.form.title.$touch()"
              :state="!$v.form.title.$error"
            ></b-form-input>
            <b-form-invalid-feedback id="title-input">
              <span v-if="!$v.form.title.required">
                Title is required
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="mb-3"
            label="Platform"
            label-cols="2">
            <b-form-radio-group
              class="pt-2"
              v-model="form.platform"
              :options="platforms"
              name="platforms">
            </b-form-radio-group>
          </b-form-group>

          <b-form-group class="mb-3" v-if="form.platform === 'website'">
            <vue-editor
              :class="!showWebContErr ?
                'valid-editor' : 'error-editor'"
              v-model="form.webContent"
              :editorOptions="editorOptions"
            ></vue-editor>
            <div
              class="error"
              v-if="form.platform === 'website' && showWebContErr">
              <span> Website content is required </span>
            </div>
          </b-form-group>

          <b-form-group
            class="mb-3"
            label="Content"
            label-cols="2"
            v-if="form.platform === 'mobile'">
            <b-form-input
              autocomplete="off"
              type="text"
              placeholder="Enter Mobile Content"
              v-model.trim="$v.form.mobContent.$model"
              @input="$v.form.mobContent.$touch()"
              :state="!$v.form.mobContent.$error"
            ></b-form-input>
            <b-form-invalid-feedback>
              <span v-if="!$v.form.mobContent.required">
                Mobile content is required
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group
            class="mt-3"
            label="Link"
            label-for="link"
            label-cols="2"
            horizontal>
            <b-form-input
              id="link"
              type="text"
              aria-describedby="link-input"
              autocomplete="off"
              placeholder="Enter Link"
              v-model.trim="$v.form.link.$model"
              @input="$v.form.link.$touch()"
              :state="!$v.form.link.$error"
            ></b-form-input>
            <b-form-invalid-feedback id="link-input">
              <span v-if="!$v.form.link.required">
                Link is required
              </span>
            </b-form-invalid-feedback>
          </b-form-group> -->

          <!-- <b-form-group
            class="mb-1"
            label="Recipient"
            label-for="filter"
            label-cols="2"
            horizontal>
            <b-form-radio-group
              class="pt-2"
              v-model="form.filter"
              :options="filters"
              name="filters">
            </b-form-radio-group>
          </b-form-group> -->

          <!-- Filter by Location -->
          <!-- <div class="mb-3" v-if="form.filter === 'location'">
            <b-form-group
              class="mb-1"
              label="Country"
              label-align="right"
              label-cols="2">
              <multiselect
                label="name"
                placeholder="Country"
                track-by="id"
                v-model="form.recipient.country"
                :options="countries"
                :searchable="true"
                @select="onSelectCountry"
                @remove="resetStateCities"
              ></multiselect>
              <div class="error ml-1" v-if="$v.form.recipient.country.$error">
                Country is required
              </div>
            </b-form-group>

            <b-form-group
              class="mb-1"
              label="State"
              label-align="right"
              label-cols="2">
              <multiselect
                label="name"
                placeholder="State"
                track-by="id"
                v-model="form.recipient.state"
                :disabled="states.length === 0"
                :multiple="true"
                :options="states"
                :searchable="true"
                @select="onSelectState"
                @remove="onRemoveState"
              ></multiselect>
            </b-form-group>
            
            <b-form-group
              class="mb-1"
              label="City"
              label-align="right"
              label-cols="2">
              <multiselect
                group-label="state"
                group-values="cities"
                label="name"
                placeholder="City"
                track-by="id"
                v-model="form.recipient.city"
                :disabled="stateCities.length === 0"
                :multiple="true"
                :options="stateCities"
                :searchable="true"
              ></multiselect>
            </b-form-group>
          </div> -->

          <!-- Filter By School -->
          <!-- <div class="mb-2" v-if="form.filter === 'school'">
            <b-form-group
              class="mb-1 pb-1"
              label="School"
              label-align="right"
              label-cols="2">
              <school-widget
                @changed="setSchool"
                :initial="currentSchool"
                ref="schoolWidget"
              ></school-widget>
            </b-form-group>
          </div> -->

          <!-- <b-form-group label="Timezone" label-cols="2">
            <b-form-select
              class="form-control"
              v-model="form.timezone"
              :options="timezones"
            ></b-form-select>
          </b-form-group> -->

          <!-- <b-form-group
            class="mb-1"
            label="Start"
            label-cols="2">
            <div class="row">
              <div class="col-6">
                <b-input-group>
                  <b-form-input
                    v-model="form.startDate"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    @input="$v.form.startDate.$touch()"
                    :state="!$v.form.startDate.$error"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.startDate"
                      :close-button="true"
                      :reset-button="true"
                      :button-only="true"
                      :min="minDate"
                      right
                      locale="en-US"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-invalid-feedback>
                    <span v-if="!$v.form.startDate.required">
                      Start Date is required
                    </span>
                    <span v-if="!$v.form.startDate.date &&
                      $v.form.startDate.required">
                      Start Date is invalid
                    </span>
                    <***!-- <span v-if="!$v.form.startDate.minDate &&
                      $v.form.startDate.date && $v.form.startDate.required">
                      Past date is not allowed
                    </span> --**>
                    <***!-- <span v-if="!$v.form.startDate.valid &&
                      $v.form.startDate.date &&
                      $v.form.startDate.required">
                      Must be earlier than End Date
                    </span> --***>
                   
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>
              <div class="col-6">
                <***!-- <b-form-input
                  class="time-input"
                  size="sm"
                  type="time"
                  v-model="form.startTime"
                  @input="$v.form.startTime.$touch()"
                  :state="!$v.form.startTime.$error"
                ></b-form-input>
                <b-form-invalid-feedback>
                  <span v-if="!$v.form.startTime.required">
                    Start Time is required
                  </span>
                </b-form-invalid-feedback> --***>

                <b-input-group>
                  <b-form-input
                    class="time-input"
                    type="text"
                    v-model="form.startTime"
                    placeholder="hh:mm"
                    @input="$v.form.startTime.$touch()"
                    :state="!$v.form.startTime.$error">
                  </b-form-input>

                  <b-input-group-append>
                    <b-form-select
                      v-model="form.startUnit"
                      :options="unitOptions"
                    ></b-form-select>
                  </b-input-group-append>

                  <b-form-invalid-feedback>
                    <span v-if="!$v.form.startTime.required">
                      Start Time is required
                    </span>
                    <span v-if="(!$v.form.startTime.time ||
                      !$v.form.startTime.maxLength) &&
                      $v.form.startTime.required">
                      Start Time is not allowed
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            class="mb-1"
            label="End"
            label-cols="2">
            <div class="row">
              <div class="col-6">
                <b-input-group>
                  <b-form-input
                    v-model="form.endDate"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    @input="$v.form.endDate.$touch()"
                    :state="!$v.form.endDate.$error"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.endDate"
                      :close-button="true"
                      :reset-button="true"
                      :button-only="true"
                      :min="minDate"
                      right
                      locale="en-US"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-invalid-feedback>
                    <span v-if="!$v.form.endDate.required">
                      End Date is required
                    </span>
                    <span v-if="!$v.form.endDate.date &&
                      $v.form.endDate.required">
                      End Date is invalid
                    </span>
                    <****!-- <span v-if="!$v.form.endDate.minDate &&
                      $v.form.endDate.date && $v.form.endDate.required">
                      Past date is not allowed
                    </span> --****>
                    <span v-if="!$v.form.endDate.valid &&
                      $v.form.endDate.date && $v.form.endDate.required">
                      Must be later or the same as Start Date
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>
              <div class="col-6">
                <b-input-group>
                  <b-form-input
                    class="time-input"
                    type="text"
                    v-model="form.endTime"
                    placeholder="hh:mm"
                    @input="$v.form.endTime.$touch()"
                    :state="!$v.form.endTime.$error">
                  </b-form-input>

                  <b-input-group-append>
                    <b-form-select
                      v-model="form.endUnit"
                      :options="unitOptions"
                    ></b-form-select>
                  </b-input-group-append>

                  <b-form-invalid-feedback>
                    <span v-if="!$v.form.endTime.required">
                      End Time is required
                    </span>
                    <span v-if="!$v.form.endTime.valid &&
                      $v.form.endTime.required">
                      Must be later than Start Time
                    </span>
                    <span v-if="(!$v.form.endTime.time ||
                      !$v.form.endTime.maxLength) &&
                      $v.form.endTime.required">
                      End Time is not allowed
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>
            </div>
          </b-form-group> -->
          <!-- <b-form-group
            class="mb-1"
            label="Playlist"
            label-cols="2">
            <b-form-select
              v-model="form.playlist"
              :options="playlists"
              placeholder="Select a playlist"
            >
            </b-form-select>
          </b-form-group> -->
          <b-form-group
            class="mb-3"
            label="Media Type"
            label-cols="2"
            horizontal
          >
            <b-form-select
              id="mediaType"
              aria-describedby="media-type-input"
              :options="mediaTypes"
              v-model="form.mediaType"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            class="mb-3"
            label="Media"
            label-cols="2"
            horizontal
          >
            <b-form-input
              v-if="form.mediaType === 'YouTube'"
              id="link"
              type="text"
              aria-describedby="youtube-link-input"
              autocomplete="off"
              placeholder="Enter YouTube Link"
              v-model.trim="$v.form.link.$model"
              @input="$v.form.link.$touch()"
              :state="!$v.form.link.$error"
            ></b-form-input>
            <b-form-select
              v-else
              id="playlist"
              aria-describedby="playlist-input"
              :options="playlists"
              v-model="form.playlist"
              @change="handlePlaylistChange"
            >
            </b-form-select>
            <b-form-invalid-feedback v-if="form.mediaType === 'YouTube'" id="youtube-link-input">
              <span v-if="!$v.form.link.required">
                YouTube link is required
              </span>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-else id="playlist-input">
              <span v-if="!$v.form.playlist.$pending && !$v.form.playlist.$model">
                Please select a playlist.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>

        <div class="container-fluid text-right mt-3">
          <button class="btn btn-outline-success btn-sm"
                  type="submit"
                  :disabled="$v.$invalid || !isFormEdited"
                  @click="editAnnouncement">
            Submit
          </button>
          <button class="btn btn-outline-danger btn-sm ml-1"
                  @click="$emit('close')">
            Cancel
          </button>
        </div>
      </div>
    </div>
    <div class="dialog-footer mt-2">
      
    </div>

    <!-- Loading -->
    <AppLoaderAdmin :isLoading="isLoading" />
  </div>
</template>

<script>
  const time = helpers.regex('time', /(1[012]|[1-9]):[0-5][0-9]/);
  const date = helpers.regex('date', /^\d{4}-\d{2}-\d{2}$/);

  import { required } from 'vuelidate/lib/validators';
  import { VueEditor, Quill } from "vue2-editor";
  import Emoji from "quill-emoji";
  import "quill-emoji/dist/quill-emoji.css";
  import _ from 'lodash';
  import moment from 'moment-timezone';
  import { helpers, maxLength } from 'vuelidate/lib/validators';
  
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  

  Quill.register(
    {
      "formats/emoji"            : Emoji.EmojiBlot,
      "modules/short_name_emoji" : Emoji.ShortNameEmoji,
      "modules/toolbar_emoji"    : Emoji.ToolbarEmoji,
      "modules/textarea_emoji"   : Emoji.TextAreaEmoji,
    },
    true
  );

  const editorOptions = {
    modules : {
      toolbar : {
        container : [
          [{ header : [ false, 1, 2, 3, 4, 5, 6 ] }],
          [ "bold", "italic", "underline" ],
          [ { align : "" }, { align : "center" } ],
          [ { list : "ordered" }, { list : "bullet" } ],
          [ { color : [] }, { background : [] } ],
          ["link"],
          ["emoji"],
        ],
        handlers : {
          emoji : function() {},
        },
      },
      short_name_emoji : true,
      toolbar_emoji    : true,
      textarea_emoji   : false,
    },
    placeholder : 'Enter Website Content',
  };

  export default {
    name   : 'EditAnnouncement',
    mixins : [
      BreakpointMixin,
    ],
    props : {
      data : Object,
    },
    data() {
      return {
        isLoading    : false,
        isFormEdited : false,
        countries    : [],
        unitOptions  : [
          { value : 'AM', text : 'AM' },
          { value : 'PM', text : 'PM' },
        ],
        form : {
          title      : null,
          webContent : '',
          mobContent : null,
          recipient  : {
            country : [],
            state   : [],
            city    : [],
            school  : null,
          },
          platform  : null,
          filter    : null,
          startDate : null,
          startTime : null,
          startUnit : null,
          endDate   : null,
          endTime   : null,
          endUnit   : null,
          timezone  : "",
          link      : null,
          mediaType: 'Playlists',
          playlist: null,
        },
        filters : [ {
          text  : 'All',
          value : 'all',
        }, {
          text  : 'Location',
          value : 'location',
        }, {
          text  : 'School',
          value : 'school',
        } ],
        platforms : [ {
          text  : 'Mobile',
          value : 'mobile',
        }, {
          text  : 'Website',
          value : 'website',
        } ],
        states      : [],
        stateCities : [],
        minDate     : new Date(),
        timezones   : [ {
          value    : null,
          text     : 'Timezone',
          disabled : true,
        }, {
          value : 'America/New_York',
          text  : 'EST/EDT',
        }, {
          value : 'America/Chicago',
          text  : 'CST/CDT',
        }, {
          value : 'America/Denver',
          text  : 'MDT',
        }, {
          value : 'America/Phoenix',
          text  : 'MST No DST',
        }, {
          value : 'America/Los_Angeles',
          text  : 'PST/PDT',
        }, {
          value : 'America/Anchorage',
          text  : 'AKST/AKDT',
        }, {
          value : 'America/Adak',
          text  : 'HDT',
        }, {
          value : 'Pacific/Honolulu',
          text  : 'HST No DST',
        } ],
        newRecipient   : {},
        updateFields   : {},
        addRecipient   : {},
        delRecipient   : {},
        currentSchool  : null,
        showWebContErr : false,
        playlists: [],
        mediaTypes: [
          { value: 'YouTube', text: 'YouTube' },
          { value: 'Playlists', text: 'Playlists' }
        ],
      }
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
      // 'SchoolWidget'   : () => import('@/components/shared/SchoolWidget'),
      // 'Multiselect'    : () => import('vue-multiselect'),
      VueEditor,
    },
    computed : {
      editorOptions() {
        return editorOptions;
      },
    },
    watch : {
      'form.filter'(value) {
        if (value !== 'location') {
          this.form.recipient.country = [];
          this.resetStateCities();
          this.$v.form.recipient.country.$reset();
        }

        if (value !== 'school') {
          this.currentSchool = null;
          this.form.recipient.school = null;
          this.$v.form.recipient.school.$reset();
        }
      },
      'form.webContent'(value) {
        if (value) {
          const txtReplacement = {
            'ql-align-center'  : 'text-center',
            'ql-align-right'   : 'text-right',
            'ql-align-justify' : 'text-justify',
            'ql-size-small'    : 'small',
          };
          const content = value.replace(
            /ql-align-center|ql-align-right|ql-align-justify|ql-size-small/gi,
            function(matched) {
              return txtReplacement[matched];
            });
          this.form.webContent = content;
          this.showWebContErr = false;
        } else
          this.showWebContErr = true;
      },
      'form.state'(value) {
        if (value === null)
          this.stateCities = [];
      },
      'form.timezone'(value) {
        if (value)
          this.minDate = moment().tz(value).format('YYYY-MM-DD');
      },
      'form.platform'(value) {
        if (value === this.data.platform) {
          this.form.webContent = (this.data.platform === 'website') ?
            this.data.content : null;
          this.form.mobContent = (this.data.platform === 'mobile') ?
            this.data.content : null;
        } else {
          if (value === 'mobile') {
            this.form.mobContent = null;
            this.$v.form.mobContent.$reset();
          } else {
            this.form.webContent = null;
            this.showWebContErr = false;
          }
        }
      },
      'form.startDate'(value) {
        if (value)
          this.$v.form.startDate.$touch();
      },
      'form.endDate'(value) {
        if (value) {
          this.$v.form.endDate.$touch();
          this.$v.form.endTime.$touch();
        }
      },
      'form.endUnit'(value) {
        if (value)
          this.$v.form.endTime.$touch();
      },
    },
    methods : {

      fetchPlaylists() {
        this.$http.get('api/playlists?page=1&perPage=100')
          .then(response => {
            this.playlists = response.data.data.data.map(playlist => ({
              value: playlist.music_playlist_id,
              text: playlist.name
            }));


            // this.playlists.unshift({
            //   text: "Select a playlist",
            //   value: null,
            //   disabled: true,
            // });
          })
          .catch(error => {
            console.error('Error fetching playlists:', error);
          });
      },

      handlePlaylistChange(value) {

        const selectedPlaylist = this.playlists.find(playlist => playlist.value === value);
        if (selectedPlaylist) {

          const slugPlaylist = this.generateLink(selectedPlaylist.text, 'playlist');
          const transformedLink = `${process.env.VUE_APP_BASE_URL}playlist/${value}/${slugPlaylist}`
          this.form.link = transformedLink;

        }

      },

      /**
       * Edit Announcement
       */
      editAnnouncement() {

        this.isLoading = true;
        this.prepareParams();

        // set default time zone
        moment.tz.setDefault(this.form.timezone);

        this.$http.put('api/announcement', {
          announcementId : this.data.announcementId,
          timezone       : this.form.timezone,
          filter         : this.form.filter,
          updateFields   : this.updateFields,
          addRecipient   : this.addRecipient,
          delRecipient   : this.delRecipient,
        }).then(() => {
          this.$parent.$emit('reload-table');
          this.$emit('close');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Updated Announcement successfully!',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.isLoading = false;
        });
      },

      /**
       * Prepare Edit Announcement params
       */
      prepareParams() {
        moment.tz.setDefault(this.form.timezone);
        const startTime = moment(this.form.startTime + ' ' +
          this.form.startUnit, 'hh:mm A').format('HH:mm');
        const endTime = moment(this.form.endTime + ' ' +
          this.form.endUnit, 'hh:mm A').format('HH:mm');
          
        if (this.form.platform === 'mobile') {
          if (this.form.mobContent !== this.data.content)
            this.updateFields.content = this.form.mobContent;
        } else {
          if (this.form.webContent !== this.data.content)
            this.updateFields.content = this.form.webContent;
        }

        if (this.form.title !== this.data.title)
          this.updateFields.title = this.form.title;

        if (this.form.link !== this.data.link)
          this.updateFields.link = this.form.link;

        if (this.form.mediaType !== this.data.mediaType)
          this.updateFields.media_type = this.form.mediaType;

        if (this.form.playlist !== this.data.playlist)
          this.updateFields.playlist_id = this.form.playlist;

        if (this.form.platform !== this.data.platform)
          this.updateFields.platform = this.form.platform;

        if (this.form.filter !== this.data.filter)
          this.updateFields.filter = this.form.filter;

        if (this.form.timezone !== this.data.timezone)
          this.updateFields.schedule_timezone = this.form.timezone;

        if (this.form.startDate !== this.data.startDate ||
          startTime !== this.data.startTime )
          this.updateFields.start_date = this.form.startDate + ' ' + startTime;

        if (this.form.endDate !== this.data.endDate ||
          endTime !== this.data.endTime)
          this.updateFields.end_date = this.form.endDate + ' ' + endTime;

        this.updateFields.status = 'Unsent';
        

        // Recipient
        if (this.data.recipient) {
          // Add Recipient
          this.addRecipient = {
            country : (JSON.stringify(this.form.recipient.country) !==
              JSON.stringify(this.data.recipient.country) &&
              this.form.filter === 'location') ?
              [this.form.recipient.country] :
              _.differenceWith(this.form.recipient.country,
                               this.data.recipient.country,
                               _.isEqual),
            state : _.differenceWith(this.form.recipient.state,
                                     this.data.recipient.state,
                                     _.isEqual),
            city : _.differenceWith(this.form.recipient.city,
                                    this.data.recipient.city,
                                    _.isEqual),
            school : (this.form.recipient.school !==
              this.data.recipient.school) ?
              this.form.recipient.school : null,
          };
          
          // Delete Recipient
          this.delRecipient = {
            country : (JSON.stringify(this.form.recipient.country) !==
              JSON.stringify(this.data.recipient.country) &&
              this.form.filter === 'location') ?
              this.data.recipient.country :
              _.differenceWith(this.data.recipient.country,
                               this.form.recipient.country,
                               _.isEqual),
            state : _.differenceWith(this.data.recipient.state,
                                     this.form.recipient.state,
                                     _.isEqual),
            city : _.differenceWith(this.data.recipient.city,
                                    this.form.recipient.city,
                                    _.isEqual),
            school : (this.form.recipient.school !==
              this.data.recipient.school) ?
              this.data.recipient.school : null,
          };
        } else {
          this.addRecipient = {
            country : (this.form.recipient.country &&
              this.form.filter === 'location') ?
              [this.form.recipient.country] : [],
            state : (this.form.recipient.state) ?
              this.form.recipient.state : [],
            city   : (this.form.recipient.city) ? this.form.recipient.city : [],
            school : (this.form.recipient.school &&
              this.form.filter === 'school') ?
              this.form.recipient.school : null,
          };
        }
      },

      /**
       * Get States
       * @param countryId
       */
      getStates(countryId) {
        this.$http.get('api/state/by_country', {
          params : {
            id : countryId,
          },
        }).then(response => {
          this.states = response.data;
        }).catch(() => {
          this.states = [];
        });
      },

      /**
       * Get Cities
       * @param state
       */
      getCities(state) {
        this.$http.get('api/city/by_state', {
          params : {
            id : state.id,
          },
        }).then(response => {
          this.stateCities.push({
            stateId : state.id,
            state   : state.name,
            cities  : response.data,
          });
        })
      },

      /**
       * On Select Country
       */
      onSelectCountry(selectedOption) {
        this.$v.form.recipient.country.$touch();
        this.resetStateCities();
        this.getStates(selectedOption.id);
      },

      /**
       * Reset State and City
       */
      resetStateCities() {
        // Clear State and City fields
        this.form.recipient.state = null;
        this.form.recipient.city = null;

        // Reset States and Cities selection list
        this.states = [];
        this.stateCities = [];
      },

      /**
       * On Select State
       */
      onSelectState(selectedOption) {
        this.getCities(selectedOption);
      },

      /**
       * On Remove State
       */
      onRemoveState({value, id}) {
        // update city selection
        this.stateCities = this.stateCities.filter(item => {
          return item.stateId !== id;
        });

        // update selected cities
        this.form.recipient.city = this.form.recipient.city.filter(item => {
          return item.state_id !== id;
        });
      },

      /**
       * Set School
       * @param schoolId
       */
      setSchool(schoolId) {
        if (schoolId)
          this.form.recipient.school = schoolId;
        else
          this.form.recipient.school = null;
      },

      /**
       * Disable dates in Calendar Picker
       * @param ymd year month date
       * @param date date as object
       * @return {Boolean}
       */
      // startDateDisabled(ymd, date) {
      //   const today = new Date();
      //   today.setDate(today.getDate() - 1); // to include current date

      //   return date < today;
      // },

      /**
       * Disable dates in End Date Calendar Picker
       * @param ymd year month date
       * @param date date as object
       * @return {Boolean}
       */
      // endDateDisabled(ymd, date) {
      //   const start = new Date(this.form.startDate);
      //   start.setDate(start.getDate());

      //   return date < start;
      // },

      /**
       * Initialize Data
       */
      initializeData() {
        if (this.data.platform === 'mobile') {
          this.form.mobContent = this.data.content;
          this.form.webContent = null;
        } else {
          this.form.mobContent = null;
          this.form.webContent = this.data.content.replace(/\?/gi, '');
        }

        // Time
        moment.tz.setDefault(this.data.timezone);
        const startTime = moment(this.data.startTime, 'HH:mm');
        const endTime = moment(this.data.endTime, 'HH:mm');
        this.form.startUnit = startTime.format('A');
        this.form.endUnit = endTime.format('A');
        this.form.startTime = (this.data.startTime !== 'Invalid date') ?
          startTime.format('hh:mm') : '';
        this.form.endTime = (this.data.endTime !== 'Invalid date') ?
          endTime.format('hh:mm') : '';
        
        this.form.title = this.data.title;
        this.form.link = this.data.link;
        this.form.mediaType = this.data.mediaType;
        this.form.playlist = this.data.playlist;
        this.form.platform = this.data.platform;
        this.form.filter = this.data.filter;
        this.form.startDate = (this.data.startDate !== 'Invalid date') ?
          this.data.startDate : '';
        this.form.endDate = (this.data.endDate !== 'Invalid date') ?
          this.data.endDate : '';
        this.form.timezone = (this.data.timezone) ? this.data.timezone : null;
        this.minDate = moment().tz(this.form.timezone).format('YYYY-MM-DD');

        // Recipient
        if (this.data.recipient) {
          if (this.data.recipient.country.length > 0) {
            this.form.recipient.country = this.data.recipient.country;
            this.getStates(this.data.recipient.country[0].id);
          } else
            this.form.recipient.country = [];

          if (this.data.recipient.state.length > 0) {
            this.form.recipient.state = this.data.recipient.state;
            for (let i = 0; i < this.data.recipient.state.length; i++)
              this.getCities(this.data.recipient.state[i]);
          } else
            this.form.recipient.state = [];

          if (this.data.recipient.city.length > 0)
            this.form.recipient.city = this.data.recipient.city;
          else
            this.form.recipient.city = [];

          if (this.data.recipient.school)
            this.currentSchool = this.data.recipient.school;
        }
      },

      /**
       * Check if Form is Edited
       * @param newVal
       */
      formUpdated(newVal) {
        this.isFormEdited = false;
        moment.tz.setDefault(this.form.timezone);
        const newStartTime = moment(newVal.startTime + ' ' +
          newVal.startUnit, 'hh:mm A').format('HH:mm');
        const newEndTime = moment(newVal.endTime + ' ' +
          newVal.endUnit, 'hh:mm A').format('HH:mm');

        if (newVal.title !== this.data.title ||
          newVal.link !== this.data.link ||
          newVal.platform !== this.data.platform ||
          newVal.filter !== this.data.filter ||
          newVal.startDate !== this.data.startDate ||
          newStartTime !== this.data.startTime ||
          newVal.endDate !== this.data.endDate ||
          newEndTime !== this.data.endTime ||
          newVal.timezone !== this.data.timezone)
          this.isFormEdited = true;

        // Filter
        if (this.data.filter === 'school') {
          if (newVal.recipient.school !== this.data.recipient.school)
            this.isFormEdited = true;
        } else if (this.data.filter === 'location') {
          if (newVal.recipient.country !== this.data.recipient.country ||
            newVal.recipient.state !== this.data.recipient.state ||
            newVal.recipient.city !== this.data.recipient.city)
            this.isFormEdited = true;
        }

        // Platform
        if (this.data.platform === 'mobile') {
          if (newVal.mobContent !== this.data.content)
            this.isFormEdited = true;
        } else if (this.data.platform === 'website') {
          if (newVal.webContent !== this.data.content)
            this.isFormEdited = true;
        }
      },

      generateSlug(text) {
        return text
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-')           // Reemplaza espacios con -
          .replace(/[^\w-]+/g, '')        // Elimina caracteres no deseados
          .replace(/--+/g, '-')           // Reemplaza múltiples - con uno solo
          .replace(/^-+/, '')             // Elimina - al inicio
          .replace(/-+$/, '');            // Elimina - al final
      },

      generateLink(text, type) {
        const slug = this.generateSlug(text);
        return slug;
        // return { 
        //   name: 'MusicSelectedPage', 
        //   params: { 
        //     type: type === 'album' ? 'music' : type,
        //     slug: slug, 
        //     id: album.albumId || album.id ,
        //     album: album
        //   } 
        // };
      },
    },
    created() {
      this.$watch('form', this.formUpdated, {
        deep : true,
      });

      this.fetchPlaylists();
    },
    mounted() {
      this.countries = this.$store.getters['address/countries'];
      this.initializeData();
    },
    validations() {
      moment.tz.setDefault(this.form.timezone);

      return {
        form : {
          title      : { required },
          webContent : (this.form.platform === 'website') ? { required } : {},
          mobContent : (this.form.platform === 'mobile') ? { required } : {},
          // recipient  : {
          //   country : (this.form.filter === 'location') ? { required } : {},
          //   state   : {},
          //   city    : {},
          //   school  : (this.form.filter === 'school') ? { required } : {},
          // },
          // platform  : { required },
          // filter    : { required },
          startDate : {
            //required,
            date,

            // minDate() {
            //   let valid = true;
            //   if (this.form.startDate < this.minDate)
            //     valid = false;

            //   return valid;
            // },
          },
          startTime : {
            //required,
            maxLength : maxLength(5),
            time,
          },
          endDate : {
            //required,
            date,
            // valid() {
            //   let valid = true;

            //   if (this.$v.form.startDate.date &&
            //     this.form.startDate > this.form.endDate)
            //     valid = false;
              
            //   return valid;
            // },

            // minDate() {
            //   let valid = true;
            //   if (this.form.endDate < this.minDate)
            //     valid = false;

            //   return valid;
            // },
          },
          endTime : {
            //required,
            maxLength : maxLength(5),
            time,
            // valid() {
            //   let valid  = true;
            //   if (this.form.startDate === this.form.endDate &&
            //     this.form.startTime && this.form.endTime) {
            //     const startTime = moment(this.form.startTime + ' ' +
            //       this.form.startUnit, 'hh:mm A').format('HH:mm');
            //     const endTime = moment(this.form.endTime + ' ' +
            //       this.form.endUnit, 'hh:mm A').format('HH:mm');

            //     const check = moment(this.form.endDate + ' ' + endTime)
            //       .isBefore(this.form.startDate + ' ' + startTime);

            //     if (check)
            //       valid = false;
            //   }
            //   return valid;
            // },
          },
          //timezone : { required },
          // link : {  },
          // playlist: {  },
          link: {
            required: function (value) {
              return this.form.mediaType === 'YouTube' ? !!value : true;
            }
          },
          playlist: {
            required: function (value) {
              return this.form.mediaType === 'Playlists' ? !!value : true;
            }
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../assets/scss/components/admin/announcement/edit-announcement";
</style>